import React from 'react'

import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from 'src/pages/pt/us/investments/sections/_hero/_index'
import SuaVidaFinanceira from 'src/pages/pt/us/investments/sections/_sua-vida-financeira/_index'
import FAQSection from 'src/pages/pt/us/investments/sections/_faq/_index'
import FooterUs from 'src/components/Us/FooterUS/_index'

const PrivacidadeDeDados = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <SuaVidaFinanceira lang={lang} />
        <FAQSection lang={lang} pageContext={pageContext} />
        <FooterUs section='dobra_04' slugPT={pageContext.pagePT} slugEN={pageContext.slug} bgColor='bg-grayscale--500' lang='en' />
      </Layout>
    </Wrapper>
  )
}

export default PrivacidadeDeDados
